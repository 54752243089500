function loadScript() {
	let casinoItems = $(".top-casino__item");
	casinoItems.eq(0).addClass("active");
	casinoItems.eq(0).find(".top-casino__btn p").text("Less details");

	$(".top-casino__item .top-casino__btn").on("click", function () {
		var parent = $(this).parent();
		if (parent.hasClass("active")) {
			parent.removeClass("active");
			$(this).find("p").text("More details");
		} else {
			$(".top-casino__item").removeClass("active");
			parent.addClass("active");
			$(this).find("p").text("Less details");
		}
	});
}
loadScript();
