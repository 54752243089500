const swiperProducts = new Swiper(".js-horScroll-reviews", {
	slidesPerView: 2,
	spaceBetween: 8,
	navigation: {
		nextEl: ".reviews__btn.swiper-button-next",
		prevEl: ".reviews__btn.swiper-button-prev",
	},
	breakpoints: {
		319: {
			slidesPerView: "auto",
		},
		1023: {
			slidesPerView: 2,
		},
	},
});
