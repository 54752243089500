if ($(window).width() <= 1024) {
	$(".menu-item-has-children").click(function () {
		$(this).parent().children().hide();
		$(this).show();
		$(this).addClass("active");
		$(".header__prev").addClass("active");
	});

	$(".header__prev, .hamburger--js").click(function (event) {
		event.preventDefault();
		$(".header__prev").removeClass("active");
		$(".menu-item-has-children").removeClass("active");
		$(".menu-item-has-children").parent().children().show();
	});
}

if (document.getElementById("table-of-contents")) {
	let $targetBlock = $(".table-of-contents");

	$(window).on("scroll", function () {
		let scrollTop = $(this).scrollTop();
		const blockTop = $targetBlock.offset().top;
		let nextElem = $targetBlock.next().offset().top;

		if (scrollTop >= blockTop - 150) {
			$targetBlock.addClass("sticky");

			$(".table-of-contents__block ").removeClass("active");
			$(".table-of-contents__head").removeClass("active");
		}

		if (nextElem - 200 >= scrollTop) {
			$targetBlock.removeClass("sticky");
		}
	});
}
