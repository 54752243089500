const swiperProducts = new Swiper(".js-horScroll-download", {
	slidesPerView: 2,
	spaceBetween: 20,
	navigation: {
		nextEl: ".slider-btns .swiper-button-next",
		prevEl: ".slider-btns .swiper-button-prev",
	},
	breakpoints: {
		319: {
			slidesPerView: "auto",
			spaceBetween: 10,
		},
		1023: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
	},
});
